<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $t('myFavouriteTeams') }}</h5>
    <hr>
    <div class="container-fluid">
      <div class="row">
        <div class="col-4 col-sm-3 col-md-2" v-for="favourite in favourites" :key="favourite.id">
          <div class="card">
            <div class="card-header text-center bg-primary text-light">{{ favourite.title }}</div>
            <div class="card-body">
              <a :href="favourite.link">
                <img :src="favourite.image" :alt="favourite.title" class="img-fluid">
              </a>
              <div class="d-flex justify-content-end align-items-center mt-3">
                <b-button @click="deleteFavourite(favourite.code)" variant="outline-danger" size="sm"><i class="fa fa-trash px-2"></i></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
     favourites: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'team'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    deleteFavourite (favouriteId) {
      const path = `auth/${this.lang}/${this.console}/users/favourites/${favouriteId}/delete`
      this.$axios.delete(path).then(() => {
        this.favourites = this.favourites.filter(fav => {
          return fav.code !== favouriteId
        })
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/users/favourites/teams`
      this.$axios.get(path).then(response => {
        this.favourites = response.data.data
      })
    }
  }
}
</script>
